import React, { useRef, useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import InfoCard from "./InfoCard";

function BackgroundInfo() {
  const [activeCardId, setActiveCardId] = useState(null);

  // List of information to be rendered
  const infos = [
    {
      id: 1,
      content: (
        <>
          I am a skilled professional with a BSc
          in Computer Science and Applied Mathematics and a BSc Honours in Big
          Data Analytics from the University of the Witwatersrand. My work
          centers on <strong>Machine Learning</strong>,{" "}
          <strong>Artificial Intelligence</strong>,{" "}
          <strong>Cloud Computing</strong>, and{" "}
          <strong> Software Engineering</strong>. I am passionate about
          leveraging data and advanced technologies to build innovative
          solutions that solve complex problems and deliver meaningful impact.
        </>
      ),
    },
    {
      id: 2,
      content: (
        <>
          My focus is on solving real-world challenges, particularly in{" "}
          <strong>Finance</strong>, where I concentrate on innovative personal
          finance solutions and algorithmic investment strategies. I’m equally
          engaged by the transformative potential of technology in{" "}
          <strong>Agriculture</strong> and <strong>Education</strong>, exploring
          ways to drive meaningful change in these critical sectors, especially
          across <strong>Africa</strong>, where such innovations can have a
          profound impact on growth and development.
        </>
      ),
    },
    {
      id: 3,
      content: (
        <>
          Collaboration fuels my work. I thrive in environments where I can work
          with others to build impactful projects. I'm always on the lookout for
          driven individuals to collaborate on ideas that push boundaries and
          challenge norms.
        </>
      ),
    },
    {
      id: 4,
      content: (
        <>
          I’m driven by the desire to explore new opportunities that reshape how
          we live, work, and define success. I share knowledge and solutions on
          platforms like <strong>GitHub</strong>, <strong>Substack</strong>, and{" "}
          <strong>Medium</strong>. If you're interested in innovation and
          collaborative problem-solving, let’s connect!
        </>
      ),
    },
  ];

  const cardRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      let closestId = null;

      cardRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const viewportHeight = window.innerHeight;

          // Calculate the visibility percentage
          const topVisible = Math.max(0, rect.top);
          const bottomVisible = Math.min(viewportHeight, rect.bottom);
          const visibleHeight = bottomVisible - topVisible;

          // Check if at least 50% of the element is visible
          if (visibleHeight / rect.height >= 0.5) {
            closestId = index + 1; // Match `info.id` which starts at 1
          }
        }
      });

      setActiveCardId(closestId);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial call to set the closest card

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: 2,
        pt: 1,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Background
      </Typography>
      {infos.map((info, index) => (
        <Box
          ref={(el) => (cardRefs.current[index] = el)}
          key={info.id}
          data-id={info.id}
        >
          <InfoCard
            key={info.id}
            cardId={info.id}
            info={info.content}
            isActive={activeCardId === info.id}
          />
        </Box>
      ))}
    </Box>
  );
}

export default BackgroundInfo;
