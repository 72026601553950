import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import MediumIcon from "./MediumIcon";
import GitHubIcon from "@mui/icons-material/GitHub";
import SubstackIcon from "./SubstackIcon";

function Footer() {
  return (
    <Box
      sx={{
        p: 2,
        my: 1,
        textAlign: "center",
      }}
    >
      <Typography
        variant="body1"
        align="center"
        sx={{ fontStyle: "italic", m: 4 }}
      >
        "The true sign of intelligence is not knowledge but imagination." –
        Albert Einstein
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 6,
          mb: 2,
        }}
      >
        {/* Medium Icon Button */}
        <Link href="https://medium.com/@brianhulela">
          <IconButton target="_blank" rel="noopener">
            <MediumIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Link>

        {/* Substack Icon Button */}
        <Link href="https://substack.com/@brianhulela">
          <IconButton target="_blank" rel="noopener">
            <SubstackIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Link>

        {/* GitHub Icon Button */}
        <Link href="https://github.com/brianhulela">
          <IconButton target="_blank" rel="noopener">
            <GitHubIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Link>

        {/* X Icon Button */}
        {/* <Link href="https://x.com/BrianHulela">
          <IconButton target="_blank" rel="noopener">
            <XIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Link> */}
      </Box>
      <Typography
        variant="body2"
        sx={{
          mb: 2,
          fontSize: "0.875rem",
          fontWeight: 500,
          letterSpacing: "0.5px",
        }}
      >
        © {new Date().getFullYear()} Brian Hulela. All Rights Reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
