import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

const LanguageBar = ({ languagesUrl }) => {
  const [languages, setLanguages] = useState({});

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await fetch(languagesUrl);
        const data = await response.json();
        setLanguages(data);
      } catch (error) {
        console.error("Failed to fetch languages:", error);
      }
    };

    fetchLanguages();
  }, [languagesUrl]);

  // If languages object is empty, display the message
  if (Object.keys(languages).length === 0) {
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="body1" color="text.secondary">
          No languages to show
        </Typography>
      </Box>
    );
  }

  // Calculate percentages
  const total = Object.values(languages).reduce((sum, value) => sum + value, 0);
  const languagePercentages = Object.entries(languages).map(([key, value]) => ({
    name: key,
    percentage: total > 0 ? ((value / total) * 100).toFixed(2) : 0,
  }));

  // Predefined color set
  const predefinedColors = [
    "#F44336", // Red
    "#2196F3", // Blue
    "#4CAF50", // Green
    "#FFC107", // Amber
    "#9C27B0", // Purple
    "#FF5722", // Deep Orange
    "#8BC34A", // Light Green
    "#00BCD4", // Cyan
    "#3F51B5", // Indigo
    "#E91E63", // Pink
  ];

  // Generate a gradient background for the bar
  const gradientBackground = languagePercentages
    .map((lang, index) => {
      const color = predefinedColors[index % predefinedColors.length]; // Rotate through colors
      const start = languagePercentages
        .slice(0, index)
        .reduce((sum, lang) => sum + parseFloat(lang.percentage), 0);
      const end = start + parseFloat(lang.percentage);
      return `${color} ${start.toFixed(2)}% ${end.toFixed(2)}%`;
    })
    .join(", ");

  return (
    <Box sx={{ mt: 2 }}>
      {/* Display Language Bar */}
      <Box
        sx={{
          height: 6,
          borderRadius: 4,
          background: `linear-gradient(to right, ${gradientBackground})`,
          mb: 1,
        }}
      />

      {/* Legend */}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {languagePercentages.map((lang, index) => (
          <Box
            key={lang.name}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor:
                  predefinedColors[index % predefinedColors.length],
                borderRadius: "50%",
              }}
            />
            <Typography variant="caption">
              {lang.name} - {lang.percentage}%
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LanguageBar;
