import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import SubstackArticles from "./SubstackArticles";
import MediumArticles from "./MediumArticles";
import GitHubRepos from "./GitHubRepos";

function Works({ username }) {
  const [activeSectionId, setActiveSectionId] = useState(null);

  // Refs for each section
  const substackRef = useRef(null);
  const mediumRef = useRef(null);
  const githubRef = useRef(null);

  const checkVisibility = (ref) => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Calculate the visibility percentage
      const topVisible = Math.max(0, rect.top);
      const bottomVisible = Math.min(viewportHeight, rect.bottom);
      const visibleHeight = bottomVisible - topVisible;

      // Check if at least 50% is visible
      return visibleHeight / rect.height >= 0.5;
    }
    return false;
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = [substackRef, mediumRef, githubRef];
      let closestId = null;

      sections.forEach((ref, index) => {
        if (checkVisibility(ref)) {
          closestId = index;
        }
      });

      setActiveSectionId(closestId);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center", my: 2 }}>
        Works
      </Typography>

      <div ref={mediumRef}>
        <MediumArticles username={username} isActive={activeSectionId === 1} />
      </div>
      <div ref={githubRef}>
        <GitHubRepos username={username} isActive={activeSectionId === 2} />
      </div>

      <div ref={substackRef}>
        <SubstackArticles
          username={username}
          isActive={activeSectionId === 0}
        />
      </div>
    </Box>
  );
}

export default Works;
