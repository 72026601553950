import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as SubstackIconSVG } from "../substack.svg"; // Importing the SVG file

function SubstackIcon() {
  return (
    <SvgIcon
      component={SubstackIconSVG}
      viewBox="0 0 448 511.471"
      sx={{ width: "24px", height: "24px"}} // Set desired size here
    />
  );
}

export default SubstackIcon;
