import React from "react";
import { Fab } from "@mui/material";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { keyframes } from "@mui/system";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const AnimatedFab = ({ handleClickOpen, buttonText }) => {
  return (
    <Fab
      variant="extended"
      size="medium"
      color="primary"
      onClick={handleClickOpen}
      sx={{
        position: "relative",
        animation: `${pulse} 2s infinite`, // Keep the pulse animation
        // No more border-related styles
      }}
    >
      <EmailRoundedIcon sx={{ mr: 1 }} />
      {buttonText}
    </Fab>
  );
};

export default AnimatedFab;
