import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function PictureAvatar() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  return (
    <div>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
        sx={{ ml: 1 }}
      >
        <Avatar
          alt="Brian Hulela"
          src="/images/profile.jpg"
          sx={{ width: 48, height: 48, cursor: "pointer" }}
          onClick={handleClickOpen} // Open dialog when avatar is clicked
        />
      </StyledBadge>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={(theme) => ({
              position: "relative",
              display: "inline-block",
              // Removed corner styling
            })}
          >
            <div className="cornerTopRight"></div>
            <div className="cornerBottomLeft"></div>
            <img
              src="/images/profile.jpg"
              alt="Full profile"
              style={{
                width: "100%",
                maxWidth: "400px",
                display: "block",
                borderRadius: "8px", // Optional, to make the image edges look smooth
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
