import React from "react";
import { ReactTyped as Typed } from "react-typed";
import { Paper, Typography } from "@mui/material";

function TypedBox() {
  return (
    <Paper
      sx={(theme) => ({
        boxShadow: "none",
        display: "flex",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        minHeight: "200px",
        position: "relative",
        p: 3, // Padding to avoid overlapping with the corners
        backgroundColor: theme.palette.background.paper, // Background for visual contrast
        "::before, ::after, .cornerTopRight, .cornerBottomLeft": {
          content: '""',
          position: "absolute",
          width: "30px",
          height: "30px",
          borderRadius: "4px",
          border: `2px solid ${theme.palette.secondary.main}`,
        },
        "::before": {
          top: 0,
          left: 0,
          borderTopWidth: "4px",
          borderLeftWidth: "4px",
          borderBottomWidth: 0,
          borderRightWidth: 0,
        },
        "::after": {
          bottom: 0,
          right: 0,
          borderBottomWidth: "4px",
          borderRightWidth: "4px",
          borderTopWidth: 0,
          borderLeftWidth: 0,
        },
        "& .cornerTopRight": {
          top: 0,
          right: 0,
          borderTopWidth: "4px",
          borderRightWidth: "4px",
          borderBottomWidth: 0,
          borderLeftWidth: 0,
        },
        "& .cornerBottomLeft": {
          bottom: 0,
          left: 0,
          borderBottomWidth: "4px",
          borderLeftWidth: "4px",
          borderTopWidth: 0,
          borderRightWidth: 0,
        },
        
      })}
    >
      <div className="cornerTopRight"></div>
      <div className="cornerBottomLeft"></div>
      <Typography variant="body1">
        <Typed
          strings={[
            "Driven by a vision for a better Africa, I leverage Mathematics, Technology, and Data to solve challenges in Agriculture, Education, and Finance.",
            "Fostering innovation and collaboration to transform lives and shape the continent's future.",
            "Passionate about solving real-world problems with innovative solutions.",
            "Committed to empowering communities through technology and education.",
            "Exploring the intersection of data science, AI, and sustainability.",
            "Building bridges between technology and social impact.",
            "Transforming challenges into opportunities for growth and progress.",
            "Advancing Africa's future through collaboration and creativity.",
            "Turning ideas into actionable solutions for a brighter tomorrow.",
            "Harnessing technology to drive meaningful change in underserved areas.",
          ]}
          typeSpeed={50}
          backSpeed={5}
          loop
        />
      </Typography>
    </Paper>
  );
}

export default TypedBox;
