import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Import IBM Mono font
import "@fontsource/ibm-plex-mono/300.css";
import "@fontsource/ibm-plex-mono/400.css"; // Regular weight
import "@fontsource/ibm-plex-mono/500.css"; // Medium weight
import "@fontsource/ibm-plex-mono/600.css"; // SemiBold weight
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#44af69",
    },
    secondary: {
      main: "#ff4a1c",
    },
    background: {
      // black background
      default: "#020300",
      paper: "#071E22",
    },
    text: {
      primary: "#dbd5b5",
    },
  },
  typography: {
    fontFamily: "'IBM Plex Mono', monospace", // Ensure consistency in font across the theme
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
