import React, { useState, useEffect } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Button,
  CardActions,
  LinearProgress,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import SubstackIcon from "./SubstackIcon";
import AnimatedDivider from "./AnimatedDivider";

const SubstackArticles = ({ username, isActive }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          `https://api.rss2json.com/v1/api.json?rss_url=https://${username}.substack.com/feed`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Substack articles.");
        }
        const data = await response.json();
        setArticles(data.items.slice(0, 10)); // Get the top 10 articles
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [username]);

  if (loading) {
    return <LinearProgress sx={{ my: 1 }} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Paper elevation={0} sx={{ my: 1, px: 2, pb: 2, borderRadius: "16px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AnimatedDivider isActive={isActive} />
        <Button
          href={`https://${username}.substack.com/`}
          target="_blank"
          rel="noopener noreferrer"
          size="medium"
          variant="text"
          startIcon={<SubstackIcon />}
        >
          Substack Newsletters
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          overflowX: "auto",
          gap: 2,
          py: 2,
          // Scrollbar styling
          "::-webkit-scrollbar": {
            height: 10,
          },
          "::-webkit-scrollbar-thumb": {
            background: (theme) => theme.palette.text.primary, // Theme text color
            borderRadius: 4,
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: (theme) => theme.palette.text.secondary, // Darker on hover
          },
          "::-webkit-scrollbar-track": {
            background: (theme) => theme.palette.background.paper, // Light background color
            borderRadius: 4,
          },
        })}
      >
        {articles.map((article) => (
          <Card
          key={article.guid}
          elevation={1}
          sx={{
            minWidth: 300,
            maxWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Featured Image */}
          {article.enclosure?.link && (
            <CardMedia
              component="img"
              height="150"
              image={article.enclosure.link}
              alt={article.title}
            />
          )}
        
          <CardContent sx={{ flexGrow: 1 }}>
            {/* Title */}
            <Typography variant="h6" gutterBottom>
              {article.title}
            </Typography>
        
            {/* Description */}
            <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
              {article.description.length > 100
                ? `${article.description.substring(0, 100)}...`
                : article.description}
            </Typography>
        
            {/* Date */}
            <Typography variant="overline" sx={{ display: "block" }}>
              {new Date(article.pubDate).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </Typography>
          </CardContent>
        
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px solid",
              borderColor: "divider",
            }}
          >
            {/* Read More Button */}
            <Button
              size="small"
              href={article.link}
              target="_blank"
              rel="noopener noreferrer"
              endIcon={<LaunchIcon />}
            >
              Read More
            </Button>
          </CardActions>
        </Card>
        
        ))}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="outlined"
            href={`https://${username}.substack.com/`}
            target="_blank"
            rel="noopener noreferrer"
            endIcon={<LaunchIcon />}
            sx={{ minWidth: "150px" }}
          >
            View More
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default SubstackArticles;
