import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import ContactForm from "./ContactForm";
import PictureAvatar from "./PictureAvatar";
import LogoSVG from "./LogoSVG";

function AppBarComponent() {
  return (
    <AppBar position="static" color="transparent">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              mr: 2,
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoSVG />
          </Box>
          <ContactForm buttonText="Contact"/>
          <PictureAvatar />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AppBarComponent;
