import React from "react";
import AppBarComponent from "../components/AppBarComponent";
import Hero from "../components/Hero";
import { Container } from "@mui/material";
import BackgroundInfo from "../components/BackgroundInfo";
import Footer from "../components/Footer";
import Works from "../components/Works";

function Home() {
  const username = "brianhulela";

  return (
    <Container maxWidth="lg">
      <AppBarComponent />

      <Hero />

      <BackgroundInfo />
      <Works username={username} />

      <Footer />
    </Container>
  );
}

export default Home;
