import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
} from "@mui/material";
import AnimatedFab from "./AnimatedFab";
import CustomizedSnackbar from "./CustomizedSnackbar";

const ContactForm = ({buttonText}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarConfig, setSnackbarConfig] = React.useState({
    severity: "success",
    message: "Message sent successfully!",
  });

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStatus(""); // Reset status when the dialog is closed
  };

  // Track validation state for each field
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset errors
    setFormErrors({
      name: !formData.name,
      email: !formData.email || !validateEmail(formData.email),
      message: !formData.message,
    });

    // If there are any validation errors, don't submit the form
    if (
      !formData.name ||
      !formData.email ||
      !formData.message ||
      !validateEmail(formData.email)
    ) {
      setStatus("Please fill in all the fields correctly.");
      return;
    }

    setStatus("Sending...");

    try {
      const response = await fetch(
        "https://send-email-db74bsv3pa-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setStatus("Message sent successfully!");
        // Clear the form fields
        setFormData({
          name: "",
          email: "",
          message: "",
        });

        setSnackbarConfig({
          severity: "success",
          message: "Message sent successfully!",
        });
        setOpenSnackbar(true);
        // Close the dialog
        handleClose();
      } else {
        setStatus("Failed to send message. Please try again later.");
        setSnackbarConfig({
          severity: "error",
          message: "Failed to send message. Please try again later.",
        });
        setOpenSnackbar(true);
        handleClose();
      }
    } catch (error) {
      console.error(error);
      setStatus("An error occurred. Please try again.");
      setSnackbarConfig({
        severity: "error",
        message: "Failed to send message. Please try again later.",
      });
      setOpenSnackbar(true);
      handleClose();
    }
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <CustomizedSnackbar
        openSnackbar={openSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarConfig={snackbarConfig}
      />
      <AnimatedFab buttonText={buttonText} handleClickOpen={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Contact Me</DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            Fill out the form below, and I’ll get back to you as soon as
            possible.
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Name"
              fullWidth
              margin="normal"
              value={formData.name}
              onChange={handleChange}
              required
              error={formErrors.name}
              helperText={formErrors.name ? "Name is required" : ""}
            />
            <TextField
              name="email"
              label="Email"
              fullWidth
              margin="normal"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              error={formErrors.email}
              helperText={
                formErrors.email ? "Please enter a valid email address" : ""
              }
            />
            <TextField
              name="message"
              label="Message"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
              required
              error={formErrors.message}
              helperText={formErrors.message ? "Message is required" : ""}
            />
            {status === "Sending..." && (
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <CircularProgress color="primary" />
                <Typography sx={{ ml: 2 }}>{status}</Typography>
              </Box>
            )}

            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ width: "100px" }}
                variant="contained"
                color="primary"
              >
                Send
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ContactForm;
