import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as LogoIcon } from "../logo512.svg"; // Importing the SVG file

function LogoSVG() {
  return (
    <SvgIcon
      component={LogoIcon}
      viewBox="0 0 37.170784 37.170776"
      sx={{ width: "40px", height: "40px" }} // Set desired size here
    />
  );
}

export default LogoSVG;
