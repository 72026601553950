import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import AnimatedDivider from "./AnimatedDivider";
import ContactForm from "./ContactForm";

function InfoCard({ info, cardId, isActive }) {
  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: "16px",
        minHeight: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <AnimatedDivider isActive={isActive} />

      <CardContent
        sx={{
          marginTop: "auto", // Push it down
          marginBottom: "auto", // Push it up
          textAlign: "center", // Optional: Ensure text is centered horizontally
          px: 6,
          pb: 6,
        }}
      >
        <Typography variant="body1">{info}</Typography>
        {cardId === 4 && (
          <Box sx={{ mt: 2 }}>
            <ContactForm buttonText="Connect"/>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default InfoCard;
