import React, { useState, useEffect } from "react";
import {
  Paper,
  Card,
  CardContent,
  Typography,
  Chip,
  Button,
  LinearProgress,
  CardActions,
} from "@mui/material";
import { Box } from "@mui/system";
import LaunchIcon from "@mui/icons-material/Launch";
import GitHubIcon from "@mui/icons-material/GitHub";
import AnimatedDivider from "./AnimatedDivider";
import LanguageBar from "./LanguageBar";

const GitHubRepos = ({ username, isActive }) => {
  const [repos, setRepos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await fetch(
          `https://api.github.com/users/${username}/repos`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch repositories");
        }
        const data = await response.json();

        // Sort repositories by creation date (newest first)
        const sortedRepos = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        // Slice the array to only get the top 10 repositories
        const topRepos = sortedRepos.slice(0, 5);

        setRepos(topRepos);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRepos();
  }, [username]);

  if (loading) {
    return <LinearProgress sx={{ my: 1 }} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formatRepoName = (name) => {
    return name
      .replace(/[^a-zA-Z0-9 ]/g, " ") // Replace special characters with spaces
      .split(" ") // Split into words
      .filter(Boolean) // Remove empty strings
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(" "); // Join back into a single string
  };

  const getRelativeTime = (updatedAt) => {
    const now = new Date();
    const updatedDate = new Date(updatedAt);
    const diffMs = now - updatedDate;

    const minutes = Math.floor(diffMs / (1000 * 60));
    const hours = Math.floor(diffMs / (1000 * 60 * 60));
    const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days <= 2) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else {
      return ""; // Do not show anything for more than 2 days
    }
  };

  return (
    <Paper elevation={0} sx={{ mt: 2, px: 2, pb: 2, borderRadius: "16px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AnimatedDivider isActive={isActive} />
        <Button
          href="https://github.com/Brianhulela"
          target="_blank"
          rel="noopener noreferrer"
          startIcon={<GitHubIcon />}
          size="medium"
          variant="text"
        >
          GitHub Projects
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          overflowX: "auto",
          gap: 2,
          py: 2,
          // Scrollbar styling
          "::-webkit-scrollbar": {
            height: 10,
          },
          "::-webkit-scrollbar-thumb": {
            background: (theme) => theme.palette.text.primary, // Theme text color
            borderRadius: 4,
          },
          "::-webkit-scrollbar-thumb:hover": {
            background: (theme) => theme.palette.text.secondary, // Darker on hover
          },
          "::-webkit-scrollbar-track": {
            background: (theme) => theme.palette.background.paper, // Light background color
            borderRadius: 4,
          },
        })}
      >
        {repos.map((repo) => (
          <Card
            elevation={1}
            key={repo.id}
            sx={{
              minWidth: 300,
              maxWidth: 300,
              mx: "auto",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Card Header */}
            <Typography
              variant="h6"
              component="a"
              sx={{
                color: "inherit",
                textDecoration: "none",
                fontWeight: "bold",
                p: 2,
                borderBottom: "1px solid",
                borderColor: "divider",
              }}
            >
              {formatRepoName(repo.name || "No Name")}
            </Typography>

            {/* Card Content */}
            <CardContent
              sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              {/* Update Info */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {new Date(repo.updated_at).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                </Typography>
                {getRelativeTime(repo.updated_at) !== "" && (
                  <Chip
                    size="small"
                    label={getRelativeTime(repo.updated_at)}
                  />
                )}
              </Box>

              {/* Language Bar */}

              <LanguageBar languagesUrl={repo.languages_url} />
            </CardContent>

            {/* Card Actions */}
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Button
                href={repo.html_url}
                target="_blank"
                rel="noopener noreferrer"
                size="small"
                endIcon={<LaunchIcon />}
                color="primary"
              >
                View Repository
              </Button>
            </CardActions>
          </Card>
        ))}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            href="https://github.com/Brianhulela"
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            endIcon={<LaunchIcon />}
            sx={{ minWidth: "150px" }}
          >
            View More
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default GitHubRepos;
