import React from "react";
import { Box, Typography, Paper, Chip } from "@mui/material";
import TypedBox from "./TypedBox";

function Hero() {
  const keywords = [
    "Machine Learning",
    "AI & Cloud Computing",
    "Data Science",
    "Software Development",
    "Finance",
    "Agriculture",
    "Education",
    "Collaboration",
    "Africa",
    "Innovation",
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        textAlign: "center",
        p: 4,
        mb: 1,
        mt: 2,
        borderRadius: "16px",
      }}
    >
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Brian Hulela
      </Typography>

      <TypedBox />

      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          justifyContent: "center",
        }}
      >
        {keywords.map((keyword, index) => (
          <Chip key={index} size="small" label={keyword} />
        ))}
      </Box>
    </Paper>
  );
}

export default Hero;
