import React from "react";
import { Box, useTheme } from "@mui/material";

function AnimatedDivider({ isActive }) {
  const theme = useTheme(); // Access the MUI theme

  // Generate a list of dots with decreasing sizes
  const generateDots = () => {
    const numDots = 10; // Number of dots to generate
    const dotSize = "6px"; // Fixed size for all dots
    const dots = [];
    for (let i = 0; i < numDots; i++) {
      const delay = Math.abs(Math.floor(numDots / 2) - i) * 0.1; // Stagger animation based on position
  
      dots.push(
        <Box
          key={i}
          sx={{
            display: "inline-block",
            borderRadius: "50%",
            width: dotSize, // Use the fixed size
            height: dotSize, // Use the fixed size
            margin: "0 5px",
            transition: "transform 0.5s, opacity 0.5s, background-color 0.5s", // Smooth transitions
            transform: isActive ? "scale(1.5)" : "scale(1)", // Animation logic
            opacity: isActive ? 1 : 0.5, // Fade in/out
            backgroundColor: isActive
              ? theme.palette.secondary.main
              : theme.palette.divider,
            transitionDelay: `${delay}s`, // Stagger animation delays
          }}
        />
      );
    }
    return dots;
  };  

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 2,
        mb: 2,
      }}
    >
      {generateDots()}
    </Box>
  );
}

export default AnimatedDivider;
